import React, { useState } from "react";
import { useTable, useSortBy, useFilters, useGlobalFilter } from "react-table";
import {
  ChakraProvider, Table, Thead, Tbody, Tr, Th, Td, Button, Text, chakra,
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton,
  useDisclosure, Container,
} from "@chakra-ui/react";
import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons'


function CustomTable({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          {
            id: 'meneger'
          }
        ],
      }
    },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!
    useSortBy,
    (hooks) => {
      hooks.allColumns.push((columns) => [
        ...columns,
      ]);
    }
  );

  return (
    <>
      <Table {...getTableProps()} width='90%' variant='striped' fontSize="small">
        <Thead>
          {headerGroups.map((headerGroup) => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <Th key={column.id}
                  {...column.getHeaderProps([
                    column.getSortByToggleProps(),
                    {
                      style: column.style,
                    }
                  ])}
                  isNumeric={column.isNumeric} sx={{bg: '#e8e2db', textAlign: 'center', border:'0.5px solid black',
                    p:'4px', fontSize:"x-small"}}
                >
                  {column.render('Header')}
                  <chakra.span pl='1'>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <TriangleDownIcon aria-label='sorted descending' />
                      ) : (
                        <TriangleUpIcon aria-label='sorted ascending' />
                      )
                    ) : column.canSort ? (
                        <Text>⬍</Text>
                    ) : null}
                  </chakra.span>
                  {/* Render the columns filter UI */}
                  <div>{column.canFilter ? column.render("Filter") : null}</div>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {rows.map((row) => {
          prepareRow(row)
          return (
            <Tr {...row.getRowProps()}>
              {row.cells.map((cell) => (
                <Td {...cell.getCellProps()} isNumeric={cell.column.isNumeric}
                  sx={{padding: '4px', textAlign: 'center'}}>
                  {cell.render('Cell')}
                </Td>
              ))}
            </Tr>
          )
        })}
        </Tbody>
      </Table>
    </>
  );
}

const TableAdrPr = () => {
  const columns = React.useMemo(
    () => [
      {
        Header: 'Дата',
        accessor: 'data_vremya',
        disableFilters: true,
        disableSortBy: true,
        Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
      },
      {
        Header: 'Адрес',
        accessor: 'adres',
        disableFilters: true,
        disableSortBy: true,
        Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
      },
      {
        Header: 'МЗЛ',
        accessor: 'meneger',
        disableFilters: true,
        Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
      },
      {
        Header: 'Покуп',
        accessor: 'company',
        disableFilters: true,
        Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
      },
      {
        Header: 'Катег',
        accessor: 'categ',
        disableFilters: true,
        disableSortBy: true,
        Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
      },
      {
        Header: 'Тр. Покуп',
        accessor: 'price1',
        disableFilters: true,
        disableSortBy: true,
        Cell: row => <div>{row.value ? Number(row.value).toLocaleString('de-CH') : 0}</div>
      },
      {
        Header: 'Тр. Пост',
        accessor: 'price2',
        disableFilters: true,
        disableSortBy: true,
        Cell: row => <div>{row.value ? Number(row.value).toLocaleString('de-CH') : 0}</div>
      },
      {
        Header: 'Аренда',
        accessor: 'price3',
        disableFilters: true,
        disableSortBy: true,
        Cell: row => <div>{row.value ? Number(row.value).toLocaleString('de-CH') : 0}</div>
      },
    ],
    [],
  )

  const modal1 = useDisclosure();

  const [data_m, setData_m] = useState([]); // use an empty array as initial value

  const fetchPrice = async () => {
    const response = await fetch("/api/actual_adr")
    const data_m = await response.json()
    setData_m(data_m.data)
  };

  return (
    <Container p={0}>
      <Button
        bg='#D1EE68'
        onClick={() => {
          fetchPrice();
          modal1.onOpen();
        }}
      >
        Адреса
      </Button>
      <Modal isOpen={modal1.isOpen} size={'2xl'} onClose={modal1.onClose}>
        <ModalOverlay />
        <ModalContent textAlign='center' sx={{overflow: 'scroll'}}>
          <ModalHeader>Список адресов со спец ценами</ModalHeader>
          <ModalCloseButton />
          <ModalBody align='center' pb='20px'>
            <ChakraProvider>
              <CustomTable columns={columns} data={data_m} />
            </ChakraProvider>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Container>
  );
};

export default TableAdrPr;