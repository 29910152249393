import { Flex, Stack, Button, Heading } from '@chakra-ui/react';
import {setToken, fetchToken, setToken2, fetchToken2, setToken3, fetchToken3, setToken4, fetchToken4} from './Auth';
import {useNavigate} from "react-router-dom";

export default function Login() {
  const navigate = useNavigate();

  const returnPrice = () => {
    navigate("/home");
  }

  const returnMzl = () => {
    navigate("/mzl");
  }

  const returnDisp = () => {
    navigate("/disp");
  }

  const returnUch = () => {
    navigate("/uch");
  }

  function wait(milliseconds) {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
  }

  async function login_telegram(){  // window with alert that's all ok
    const response = await fetch(`/api/login_telegram`, {method: "GET"})
    const data1 = await response.json();
    const id_c = data1.data[0]['id']
    var link = document.createElement("a")
    link.setAttribute('rel', 'noopener noreferrer');
    link.setAttribute('target', 'about:blank');
    link.setAttribute('href', "https://t.me/AnketyRM_bot?start=" + id_c);
    link.click()
    await wait(15000);
    const response2 = await fetch(`/api/check_telegram/${id_c}`, {method: "GET"})
    const data2 = await response2.json();
    if (data2 !== null) {
      if(data2[1] === 'МЗЛ'){
        setToken2(data2[0]);
        localStorage.setItem('session', data2[2])
        navigate("/mzl");
      } else if (data2[1] === 'Админ'){
        setToken(data2[0]);
        localStorage.setItem('session', data2[2])
        navigate("/home");
      } else if (data2[1] === 'Учет'){
        setToken3(data2[0]);
        localStorage.setItem('session', data2[2])
        navigate("/uch");
      } else if (data2[1] === 'Диспетчер'){
        setToken4(data2[0]);
        localStorage.setItem('session', data2[2])
        navigate("/disp");
      }
    }
  };

  return(
   <>
     <div style = {{minHeight: 800, marginTop: 20 }}>
       <div style = {{marginTop: 50 }} >
         { fetchToken() || fetchToken2() || fetchToken3() || fetchToken4() ? (
           <Flex
             minH={'50vh'}
             align={'center'}
             justify={'center'}>
             <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
               <Heading fontSize={'3xl'}>Вход уже выполнен</Heading>
               { localStorage.getItem('me3To_keN9') ? (
                 <Button
                   onClick = {returnMzl}
                   bg={'blue.400'}
                   color={'white'}>
                   Вернуться
                 </Button>
               ) : localStorage.getItem('me3To_ke_uch') ? (
                 <Button
                   onClick = {returnUch}
                   bg={'blue.400'}
                   color={'white'}>
                   Вернуться
                 </Button>
               ) : localStorage.getItem('me3To_ke_disp') ? (
                 <Button
                   onClick = {returnDisp}
                   bg={'blue.400'}
                   color={'white'}>
                   Вернуться
                 </Button>
               ) : (
                 <Button
                   onClick = {returnPrice}
                   bg={'blue.400'}
                   color={'white'}>
                   Вернуться
                 </Button>
               )}
             </Stack>
           </Flex>
         ) : (
           <Flex
             minH={'75vh'}
             align={'center'}
             justify={'center'}>
             <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
               <Stack align={'center'}>
                 <Heading fontSize={'4xl'}>Логин</Heading>
               </Stack>
               <Stack spacing={10}>
                 <Button
                   onClick = {login_telegram}
                   bg={'blue.400'}
                   color={'white'}
                   _hover={{
                     bg: 'blue.500',
                   }}>
                   Войти через Telegram
                 </Button>
               </Stack>
             </Stack>
           </Flex>
         )
         }
       </div>
     </div>
   </>
  );
}